<template>
    <div class="sensor-detail" :class="[$mq]">
        <div class="topbar-container">
            <div class="return-icon" @click="close()"></div>
            <div class="sensor-top-container">
                <div class="sensor-ball"></div>
                <div class="sensor-name" v-if="sensor">
                    {{ sensor.name }}
                </div>
            </div>
        </div>
        <div class="detail-container hide-scrollbar">
            <div class="alert-box" v-if="sensor && sensor.last_value">
                <div class="text-content">
                    <div class="sensor-text">{{ $t('sensor.date_last_value', { date: formatDate(sensor.last_value.date * 1000, 'DD/MM/YYYY HH:mm') }) }}</div>
                    <div class="bold-text">{{ sensor.last_value.value }} {{ $tc('sensor.grades', 1) }}</div>
                </div>
                <!-- <div class="text-content" v-if="sensor.config.condition && sensor.config.value"> -->
                <div class="text-content" v-if="sensor.config && sensor.config.value">
                    <div class="bold-text">{{ $t('sensor.alarm') }}</div>
                    <div v-if="sensor.config.condition == '<>'" class="sensor-text">
                        {{ `${$t('sensor.between')} ${sensor.config.value.split(',')[0]} ${$tc('sensor.grades', 1)} ${$t('sensor.temperature_and')} ${sensor.config.value.split(',')[1]} ${$tc('sensor.grades', 1)}.` }}
                    </div>
                    <div v-else-if="sensor.config.condition == '!<>'" class="sensor-text">
                        {{ `${$t('sensor.not_between')} ${sensor.config.value.split(',')[0]} ${$tc('sensor.grades', 1)} ${$t('sensor.temperature_and')} ${sensor.config.value.split(',')[1]} ${$tc('sensor.grades', 1)}.` }}
                    </div>
                    <div v-else class="sensor-text">
                        {{ sensor.config.condition + ' ' + sensor.config.value + $tc('sensor.grades', 1) }}
                    </div>
                </div>
                <!-- <div class="text-content" v-if="sensor.config.tpl_id"> -->
                <div class="text-content" v-if="sensor.config">
                    <div class="bold-text">{{ $t('sensor.action_plan') + ':' }}</div>
                    <div class="sensor-text">{{ actionPlan }}</div>
                </div>
            </div>
            <div class="datepicker-container">
                <div class="filter date">
                    <div class="title-date">{{ $t('assets.date.filter_by') }}</div>
                    <select name="date" class="date-input" v-model="currentFilters.type_date">
                        <option v-for="(option, index) in optionsDate" :key="index" :value="option.value">{{ option.name }}</option>
                    </select>

                    <div class="custom" v-if="currentFilters.type_date == 'custom'">
                        <div class="description">{{ $t('assets.filter.from') }}:</div>
                        <input class="date-input start" v-model="currentFilters.date.start_date" :max="minDate" type="date" @change="calcDate()" />

                        <div class="separator"></div>

                        <div class="description">{{ $t('assets.filter.to') }}:</div>
                        <input class="date-input end" v-model="currentFilters.date.end_date" :min="maxDate" type="date" @change="calcDate()" />
                    </div>
                    <div class="filter-btn" v-if="showButtonFilter" @click="filterDate()">{{ $t('assets.date.filter.text') }}</div>
                </div>
            </div>

            <div class="chart-container" v-if="sensorChartValues && show">
                <apexchart :key="key" width="100%" type="line" height="300px" :options="chartOptions" :series="series"></apexchart>
            </div>
            <div class="chart-container" v-else>
                <div class="greybox"></div>
            </div>
            <div class="issues-selector">
                <Tabs :tabOneText="$t('sensor.all_issues')" :tabTwoText="totalOpenIssues ? $t('sensor.open_issues', { number: '(' + totalOpenIssues + ')' }) : $t('sensor.open_issues', { number: '' })" :selected="allIssuesSelected ? 'tabOne' : 'tabTwo'" @switchClick="openIssues"></Tabs>
            </div>
            <div class="list-issues-card-container hide-scrollbar" v-if="allIssuesSelected">
                <div class="list-issues-content hide-scrollbar">
                    <!-- <template v-if="sensorAlerts"> -->
                    <template v-if="sensorAlerts.length > 0 && sensorAlertsOpen">
                        <sensor-alert-card :sensor="sensor" v-for="(sensor, index) in totalAlertsPaginated" :key="index" />
                        <div class="pagination" v-if="page > 1 || perPage * page < total">
                            <div class="item prev" v-if="page > 1" @click="page_prev()"></div>
                            <div class="item next" v-if="perPage * page < total" @click="page_next()"></div>
                        </div>
                    </template>
                    <template v-else>
                        <EmptyPage :title="$t('sensor.empty_alerts')" :description="$t('empty_pages.sensor_description')"></EmptyPage>
                    </template>
                </div>
            </div>
            <div class="list-issues-card-container hide-scrollbar" v-else-if="!allIssuesSelected">
                <div class="list-issues-content hide-scrollbar">
                    <template v-if="openIssuesPaginated.length > 0 && sensorAlertsOpen">
                        <sensor-alert-card :sensor="issueList" v-for="(issueList, index) in openIssuesPaginated" :sensorName="sensor.name" :key="index" />
                        <div class="pagination" v-if="pageOpenIssues > 1 || perPageOpenIssues * pageOpenIssues < totalOpenIssues">
                            <div class="item prev" v-if="pageOpenIssues > 1" @click="openIssuesPagePrev()"></div>
                            <div class="item next" v-if="perPageOpenIssues * pageOpenIssues < totalOpenIssues" @click="openIssuesPageNext()"></div>
                        </div>
                    </template>

                    <template v-else>
                        <EmptyPage :title="$t('sensor.empty_alerts')" :description="$t('empty_pages.sensor_description')"></EmptyPage>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Tabs from '../ui/Tabs.vue'
import EmptyPage from '../../layout/EmptyPage.vue'
import SensorAlertCard from './sensorAlertCard.vue'
import i18n from '@/i18n'
export default {
    components: { SensorAlertCard, EmptyPage, Tabs },
    name: 'sensorDetail',
    props: {
        sensorID: {
            type: [String, Object],
            default: false
        },
        filtersActive: {
            type: Object,
            default: false
        }
    },
    data() {
        return {
            maxDate: moment().format('YYYY-MM-DD'),
            minDate: true,
            show: false,
            showButtonFilter: false,
            currentFilters: {
                type_date: 'today',
                date: {
                    start_date: false,
                    end_date: false
                }
            },
            emptyFilters: {
                type_date: 'today',
                date: {
                    start_date: false,
                    end_date: false
                }
            },
            numberOpenIssues: false,
            allIssuesSelected: true,
            page: 1,
            perPage: 20,
            total: false,
            totalAlertsPaginated: [],
            openIssuesPaginated: [],
            pageOpenIssues: 1,
            perPageOpenIssues: 20,
            totalOpenIssues: false,
            openIssuesList: false,
            sendValueCharts: false,
            key: 1,
            loadingOpenIssues: false,
            chartOptions: {
                chart: {
                    id: this.sensorID,
                    toolbar: {
                        show: false
                    },
                    background: '#fff'
                },
                grid: {
                    show: false,
                    padding: {
                        left: 0,
                        right: 0
                    }
                },
                tooltip: {
                    x: {
                        show: false
                    },
                    y: {
                        formatter: function (value) {
                            // without decimals
                            return value.toFixed(1)
                        }
                    }
                },

                xaxis: {
                    categories: [],
                    hideOverlappingLabels: true,
                    // range: 5,
                    style: {
                        fontSize: '20px'
                    }
                },
                //nodata
                noData: {
                    text: i18n.t('sensor.no_data'),
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,

                    style: {
                        color: '#000',
                        fontSize: '14px',
                        fontFamily: 'Avenir-Regular'
                    }
                },
                legend: {
                    show: false
                },
                // stroke of the line
                stroke: {
                    curve: 'smooth',
                    width: 6
                },
                datalabels: {
                    enabled: true,
                    enabledOnSeries: [0],
                    formatter: function (value) {
                        // without decimals
                        return value.toFixed(1)
                    },
                    style: {
                        colors: ['#000']
                    },
                    background: {
                        enabled: true,
                        foreColor: '#fff',
                        padding: 2,
                        borderRadius: 2,
                        borderWidth: 1,
                        borderColor: '#fff',
                        opacity: 0.9
                    }
                }
            }
        }
    },
    computed: {
        optionsDate() {
            return [
                { value: 'today', name: i18n.t('assets.date.today_last_hours') },
                { value: 'yesterday', name: i18n.t('assets.date.yesterday') },
                { value: 'week', name: i18n.t('assets.date.last_week') },
                { value: 'month', name: i18n.t('assets.date.last_month') },
                { value: 'quarter', name: i18n.t('assets.date.quarter') },
                { value: 'custom', name: i18n.t('assets.date.custom') }
            ]
        },
        actionPlan() {
            if (this.sensor && this.sensor.config && this.sensor.config.tpl_id) {
                let tpl = this.$store.getters['issues/getTemplates'](this.sensor.config.tpl_id)
                if (tpl) {
                    return tpl.name
                }
            }
            return false
        },
        series() {
            if (!this.show) {
                return []
            }
            if (this.sendValueCharts) {
                let data = []
                let serie = []
                let sensor = this.sensorChartValues
                if (sensor && typeof sensor != 'string') {
                    // if (Object.values(sensor).length > 0) {
                    //     Object.values(sensor).forEach((value) => {
                    //         data.push(value.value)
                    //     })
                    //     serie.push({
                    //         name: this.sensor.name,
                    //         data: data
                    //     })
                    // }
                    // //if has alerts add new line to series
                    // if (typeof this.sensor.config != 'undefined' && this.sensor.config != null && this.sensor.config) {
                    //     // add the same values the same times
                    //     let alertValues = []
                    //     for (let i = 0; i < data.length; i++) {
                    //         alertValues.push(this.sensor.config.value)
                    //     }
                    //     serie.push({
                    //         name: 'Alerta',
                    //         data: alertValues,
                    //         color: '#ffc700',
                    //         width: 2
                    //     })
                    // }
                    if (Object.values(sensor).length > 0) {
                        Object.values(sensor).forEach((value) => {
                            data.push(value.value)
                        })
                        serie.push({
                            name: this.sensor.name,
                            data: data
                        })
                    }
                    //if has alerts add new line to series
                    if (typeof this.sensor.config != 'undefined' && this.sensor.config != null && this.sensor.config) {
                        // add the same values the same times
                        let alertValues = []
                        let alertValues2 = []

                        // if is string means has 2 values, if not is integer with one value
                        if (typeof this.sensor.config.value == 'string') {
                            for (let i = 0; i < data.length; i++) {
                                alertValues.push(this.sensor.config.value.split(',')[0])
                                alertValues2.push(this.sensor.config.value.split(',')[1])
                            }
                        } else {
                            for (let i = 0; i < data.length; i++) {
                                alertValues.push(this.sensor.config.value)
                            }
                        }
                        serie.push({
                            name: 'Alerta',
                            data: alertValues,
                            color: '#ffc700',
                            width: 1
                        })
                        if (alertValues2.length > 0) {
                            serie.push({
                                name: 'Alerta 2',
                                data: alertValues2,
                                color: '#ffc700',
                                width: 1
                            })
                        }
                    }
                }
                return serie
            }
        },
        issueStates() {
            return this.$store.getters['getIssueStates']
        },
        sensorChartValues() {
            if (this.sensor && this.sensor.id) {
                let sensor = this.$store.getters['sensor/getSensorValues'](this.sensor.id)
                if (sensor && sensor.alerts && sensor.alerts > 0) {
                    this.hasAlerts = sensor.alerts
                }
                if (sensor && sensor.values && Object.values(sensor.values).length > 0) {
                    return sensor.values
                } else {
                    return 'nodata'
                }
            }
            return false
        },
        sensor() {
            return this.$store.getters['sensor/getSensorValues'](this.sensorID)
        },
        sensorAlerts() {
            let sensor = this.$store.getters['sensor/getSensorValues'](this.sensorID)
            if (sensor && sensor.values && Object.keys(sensor.values).length > 0) {
                let aSensor = []
                for (let index in this.sensor.values) {
                    if (sensor.values[index].alert) {
                        let sensorResult = {
                            id: sensor.id,
                            name: sensor.name,
                            data: { ref: sensor.values[index] },
                            timestamp: parseInt(index),
                            issue_id: sensor.values[index].issue ? sensor.values[index].issue.issue_id : false,
                            checklist_id: sensor.values[index].issue ? sensor.values[index].issue.id : false,
                            status: sensor.values[index].issue ? sensor.values[index].issue.status : false
                        }
                        aSensor.push(sensorResult)
                    }
                }
                // sort by timestamp
                aSensor.sort((a, b) => {
                    return b.timestamp - a.timestamp
                })

                this.total = aSensor.length
                this.totalAlertsPaginated = aSensor.slice(this.perPage * (this.page - 1), this.perPage * this.page)
                return aSensor
            }

            return false
        },
        sensorAlertsOpen() {
            let sensor = this.$store.getters['sensor/getSensorValues'](this.sensor.id)
            if (sensor.values && Object.keys(sensor.values).length > 0) {
                let aSensor = []
                for (let index in sensor.values) {
                    if (sensor.values[index] && sensor.values[index].alert && this.issueStates && this.issueStates[sensor.values[index].issue && sensor.values[index].issue.status] && this.issueStates[sensor.values[index].issue.status].type != 3) {
                        let sensorResult = {
                            id: sensor.id,
                            name: sensor.name,
                            data: { ref: sensor.values[index] },
                            timestamp: parseInt(index),
                            issue_id: sensor.values[index].issue ? sensor.values[index].issue.issue_id : false,
                            checklist_id: sensor.values[index].issue ? sensor.values[index].issue.id : false,
                            status: sensor.values[index].issue ? sensor.values[index].issue.status : false
                        }
                        aSensor.push(sensorResult)
                    }
                }
                // sort by timestamp
                aSensor.sort((a, b) => {
                    return b.timestamp - a.timestamp
                })

                return aSensor
            }

            return false
        }
    },
    methods: {
        chartFinish() {
            this.sendValueCharts = true
        },
        xAxisValues() {
            let dates = []
            let min = 9000
            let max = -1000
            //push dates with different hours
            if (this.sensorChartValues && typeof this.sensorChartValues != 'string') {
                for (let key in this.sensorChartValues) {
                    let keyInt = parseInt(key * 1000)
                    dates.push(moment(keyInt).format('DD/MM HH') + 'h')
                    // get min and max from values
                    if (this.sensorChartValues[key].value < min) {
                        min = this.sensorChartValues[key].value
                    }
                    if (this.sensorChartValues[key].value > max) {
                        max = this.sensorChartValues[key].value
                    }
                }
                // rest 5 to min and add 5 to max
                min = Math.round(min) - 3
                max = Math.round(max) + 3

                this.updateAxis(dates, min, max)
            } else if (this.sensorChartValues == 'nodata') {
                this.show = true
            }
        },
        calcDate() {
            this.currentFilters.date && this.currentFilters.date.start_date ? (this.maxDate = moment(this.currentFilters.date.start_date).format('YYYY-MM-DD')) : true
            this.currentFilters.date && this.currentFilters.date.end_date ? (this.minDate = moment(this.currentFilters.date.end_date).format('YYYY-MM-DD')) : true
            // Force update object
            this.currentFilters = { ...this.currentFilters }
        },
        close() {
            this.$emit('close', this.currentFilters)
        },
        formatDate(date, format) {
            return this.$root.formatMoment(date, format)
        },
        filterDate() {
            // change emptyFilter to a deep copy of currentFilterss
            this.emptyFilters = JSON.parse(JSON.stringify(this.currentFilters))
            this.$emit('filterDate', { id: this.$route.params.id, filters: this.currentFilters })
        },
        openIssues(tab) {
            let self = this
            this.$overlay.loading()
            // call endpoint
            this.loadingOpenIssues = true
            let allIssuesSelected = tab == 'tabOne' ? true : false

            let date = this.currentFilters
            let params = {
                sensor_id: this.sensorID ? this.sensorID : this.sensor.id,
                start_date: date.type_date != 'custom' ? date.type_date : date.date.start_date,
                end_date: date.type_date == 'custom' ? date.date.end_date : false
            }
            this.$store.dispatch('sensor/loadSensorOpenIssues', params).then((response) => {
                self.openIssuesList = response
                self.allIssuesSelected = allIssuesSelected
                self.numberOpenIssues = Object.values(response).length
                self.loadingOpenIssues = false
                self.$overlay.hide()
            })
        },
        page_next() {
            this.page++
            this.totalAlertsPaginated = this.sensorAlerts.slice(this.perPage * (this.page - 1), this.perPage * this.page)
        },
        page_prev() {
            this.page--
            this.totalAlertsPaginated = this.sensorAlerts.slice(this.perPage * (this.page - 1), this.perPage * this.page)
        },
        openIssuesPageNext() {
            this.pageOpenIssues++
            this.openIssuesPaginated = Object.values(this.openIssuesList).slice((this.pageOpenIssues - 1) * this.perPageOpenIssues, this.pageOpenIssues * this.perPageOpenIssues)
        },
        openIssuesPagePrev() {
            this.pageOpenIssues--
            this.openIssuesPaginated = Object.values(this.openIssuesList).slice((this.pageOpenIssues - 1) * this.perPageOpenIssues, this.pageOpenIssues * this.perPageOpenIssues)
        },
        updateAxis(data, min, max) {
            let self = this
            let copy = { ...this.chartOptions }

            copy.xaxis = {
                categories: data,
                convertedCatToNumeric: false,
                hideOverlappingLabels: true,
                tickAmount: 3,
                tickPlacement: 'on',
                offsetX: 30,
                labels: {
                    show: true,
                    rotate: 0,
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Avenir-Regular'
                    }
                }
            }
            copy.events = {
                mounted: this.chartFinish()
            }
            copy.yaxis = {
                // min: min,
                // max: max,
                forceNiceScale: true,
                convertedCatToNumeric: false,
                labels: {
                    show: true,
                    formatter: function (value) {
                        // without decimals
                        return value.toFixed(0)
                    }
                }
            }
            this.chartOptions = copy
            //chart is not reactive
            this.key++
            setTimeout(() => {
                self.show = true
            }, 200)
        }
    },

    mounted() {
        this.currentFilters = { ...this.filtersActive }
        this.emptyFilters = JSON.parse(JSON.stringify(this.filtersActive))
        let self = this
        setTimeout(() => {
            self.show = true
        }, 200)
        if (this.sensorChartValues) {
            this.xAxisValues()
        }
        this.openIssues('tabOne')
    },
    watch: {
        openIssuesList(newValue, oldValue) {
            if (newValue) {
                this.openIssuesPaginated = Object.values(newValue).slice(0, this.perPageOpenIssues)
                this.totalOpenIssues = Object.values(newValue).length
            }
        },
        currentFilters: {
            handler: function (newValue, oldValue) {
                // compare with empty filters if is the same
                if (JSON.stringify(newValue) !== JSON.stringify(this.emptyFilters)) {
                    this.showButtonFilter = true
                } else {
                    this.showButtonFilter = false
                }
            },
            deep: true
        },
        emptyFilters: {
            handler: function (newValue, oldValue) {
                // compare with empty filters if is the same
                if (JSON.stringify(newValue) !== JSON.stringify(this.emptyFilters)) {
                    this.showButtonFilter = true
                } else {
                    this.showButtonFilter = false
                }
            },
            deep: true
        },
        sensorChartValues(newValue, oldValue) {
            this.show = false
            if (newValue) {
                this.xAxisValues()
            }
        },
        sensor(newValue, oldValue) {
            if (newValue) {
                this.openIssues(this.allIssuesSelected ? 'tabOne' : 'tabTwo')
            }
        }
    }
}
</script>

<style lang="scss">
.sensor-detail {
    width: 100%;
    height: 100%;
    padding: 12px;

    .topbar-container {
        @include display-flex();
        @include flex-direction(row);
        @include justify-content(flex-start);
        @include align-items(center);
        margin-bottom: 6px;
        .return-icon {
            cursor: pointer;
            @include border-radius(4px);
            width: 45px;
            height: 45px;
            min-width: 45px;
            min-height: 45px;
            background-color: #fff;
            @include background($image: img('left_neutro.svg'), $size: 40%, $position: center center);

            &:hover {
                @include bgHover(#fff);
            }
        }
        .sensor-top-container {
            @include display-flex();
            @include flex-direction(row);
            @include justify-content(flex-start);
            @include align-items(center);
            padding-left: 12px;
            width: calc(100% - 50px);
            .sensor-ball {
                @include border-radius(50%);
                width: 10px;
                height: 10px;
                min-width: 10px;
                min-height: 10px;
                background-color: $done;
            }
            .sensor-name {
                @include font-size(m);
                @include text-ellipsis();
                font-family: $text-bold;
                padding-left: 12px;
                color: $neutro-s80;
            }
        }
    }
    .detail-container {
        width: 100%;
        height: calc(100% - 50px);
        overflow: scroll;
        overflow-x: hidden;

        .alert-box {
            @include border-radius(5px);
            margin-top: 10px;
            background-color: $default-sec-color;
            padding: 6px;
            margin-bottom: 10px;

            .text-content {
                @include display-flex();
                @include flex-direction(row);
                @include justify-content(flex-start);
                @include align-items(center);
                flex-wrap: wrap;

                .bold-text {
                    @include font-size(s);
                    font-family: $text-bold;
                    color: $neutro-s80;
                    margin-right: 5px;
                }
                .sensor-text {
                    @include font-size(s);
                    font-family: $text;
                    color: $neutro-s80;
                    padding-right: 5px;
                }
            }
        }

        .issues-sensor {
            width: 100%;
            height: 100%;
        }
        .chart-container {
            width: 100%;
            height: auto;
            margin: 0 auto;
        }
        .chart-container {
            width: 100%;
            height: auto;
            margin: 0 auto;

            &.nodata {
                @include display-flex();
                @include align-items(center);
                @include justify-content(center);
            }
            .greybox {
                height: 250px;
                @include background($image: img('loader_dark.svg'), $position: center center, $size: 50px, $color: #fff);
                margin-bottom: 12px;
            }
        }
        .issues-selector {
            @include display-flex();
            @include justify-content(space-between);
            @include align-items(center);
            @include border-radius(4px);
            width: 100%;
            max-width: 500px;
            height: 40px;
            padding: 3px;
            background-color: #fff;
            margin: 0 auto;
            margin-bottom: 24px;
        }
        .list-issues-card-container {
            width: 100%;
            height: auto;

            .list-issues-content {
                @include display-flex();
                width: 100%;
                height: calc(100% - 70px);
                flex-wrap: wrap;
                gap: 12px;
                align-content: flex-start;
            }
        }
    }
    .pagination {
        width: 100%;
        display: block;
        height: 50px;

        .item {
            width: calc(50% - 10px);
            height: 45px;
            float: left;
            @include border-radius(4px);
            @extend .interaction;

            &.next {
                @include background($image: img('right_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
                float: right;
            }
            &.prev {
                @include background($image: img('left_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
            }
        }
    }
    &.portrait {
        .pagination {
            width: 100%;
            padding: 0px;
            display: block;
            height: 45px;
            // margin-top: 75px;
            position: relative;
            // top: -75px;
            margin-bottom: 10px;

            .item {
                width: calc(50% - 5px);
                height: 45px;
                margin: 0 0px;
                float: left;
                @include border-radius(4px);
                @extend .interaction;

                &.next {
                    @include background($image: img('right_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
                    float: right;
                    @extend .interaction;
                }
                &.prev {
                    @include background($image: img('left_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
                }
            }
        }
    }
}
</style>
