<template>
    <div class="sensor-content hide-scrollbar" v-if="sensors && sensors.length > 0">
        <chart-sensor @selectSensor="selectSensor" :ref="'chartSensorComponent' + sensor.id" :sensor="sensor" v-for="sensor in sensors" :key="sensor.id + timestamp()" />
    </div>
    <EmptyPage v-else :buttonText="$t('sensor.no_data_button')" buttonType="create" :title="$t('sensor.no_data_title')" :description="$t('sensor.no_data_description')" @buttonAction="addSensor()" customClass="sensors-config" buttonClass="link"></EmptyPage>
</template>

<script>
import EmptyPage from '../../layout/EmptyPage.vue'
import ChartSensor from './chartSensor.vue'
export default {
    components: {
        ChartSensor,
        EmptyPage
    },
    name: 'ViewCharts',
    props: {
        sensors: {
            type: [Array, Boolean],
            default: false
        }
    },
    methods: {
        timestamp() {
            return moment().valueOf()
        },
        selectSensor(sensor) {
            this.$emit('selectSensor', sensor)
        },
        addSensor() {
            this.$store.commit('sensor/setCreateState', true)
            this.$router.push({
                name: 'ConfigPage',
                params: { page: 'sensconfig' }
            })
        }
    }
}
</script>

<style></style>
