<template>
    <div class="sensors" :class="[{ detail: sensorSelected }, $mq]">
        <template v-if="sensorSelected && $route.name == 'SensorDetail'">
            <SensorDetail @filterDate="filterDetail" :filtersActive="currentFilters" :sensorID="sensorSelected" @close="back" />
        </template>
        <template v-else>
            <div class="topbar-container">
                <div class="left-side">
                    <div class="chart-icon" @click="showCharts(true)" :class="{ selected: sensorCharts }"></div>
                    <div class="alert-icon" @click="showCharts(false)" :class="{ selected: !sensorCharts }"></div>
                </div>
                <div class="right-side">
                    <div class="refresh-icon" :class="$mq" @click="load()">
                        <span v-if="![$mq].includes('portrait')"> {{ $t('sensor.refresh') }}</span>
                        <img :src="cssImages + 'reload-retry-gray.svg'" alt="" />
                    </div>
                </div>
            </div>
            <div class="sensor-chart-container">
                <div class="datepicker-container">
                    <div class="filter date">
                        <div class="title-date">{{ $t('assets.date.filter_by') }}</div>
                        <select name="date" class="date-input" v-model="currentFilters.type_date">
                            <option v-for="(option, index) in optionsDate" :key="index" :value="option.value">{{ option.name }}</option>
                        </select>
                        <div class="filter-btn" v-if="showButtonFilter" @click="filterByDate(currentFilters)">{{ $t('assets.date.filter.text') }}</div>
                    </div>
                </div>

                <viewCharts class="slideRight" v-if="sensorCharts && printCharts && isSensorsLoaded" :sensors="sensors" @selectSensor="selectSensor"></viewCharts>
                <div v-else-if="!isSensorsLoaded || !conection" class="noConnectionBox">
                    <ConnectionBox class="noConnection" gType="sensorConnection" @reload="load"></ConnectionBox>
                </div>
                <div class="issues-sensor slideLeft" v-else>
                    <div class="issues-selector">
                        <Tabs :tabOneText="$t('sensor.all_issues')" :tabTwoText="totalOpenIssues ? $t('sensor.open_issues', { number: '(' + totalOpenIssues + ')' }) : $t('sensor.open_issues', { number: '' })" :selected="allIssuesSelected ? 'tabOne' : 'tabTwo'" @switchClick="openIssues"></Tabs>
                    </div>

                    <div class="list-issues-card-container hide-scrollbar" v-if="allIssuesSelected">
                        <div class="list-issues-content hide-scrollbar">
                            <template v-if="sensorsWithAlerts">
                                <sensor-alert-card :sensor="sensor" v-for="(sensor, index) in totalAlertsPaginated" :key="index" />
                                <div class="pagination" v-if="page > 1 || perPage * page < total">
                                    <div class="item prev" v-if="page > 1" @click="page_prev()"></div>
                                    <div class="item next" v-if="perPage * page < total" @click="page_next()"></div>
                                </div>
                            </template>
                            <template v-else>
                                <EmptyPage :title="$t('sensor.empty_alerts')" :description="$t('empty_pages.sensor_description')"></EmptyPage>
                            </template>
                        </div>
                    </div>
                    <div class="list-issues-card-container hide-scrollbar" v-else-if="!allIssuesSelected">
                        <div class="list-issues-content hide-scrollbar">
                            <template v-if="openIssuesPaginated.length > 0 && openIssues">
                                <sensor-alert-card :sensor="issueList" :sensorName="issueList.data.ref.sensor" v-for="(issueList, index) in openIssuesPaginated" :key="index" />

                                <div class="pagination" v-if="pageOpenIssues > 1 || perPageOpenIssues * pageOpenIssues < totalOpenIssues">
                                    <div class="item prev" v-if="pageOpenIssues > 1" @click="openIssuesPagePrev()"></div>
                                    <div class="item next" v-if="perPageOpenIssues * pageOpenIssues < totalOpenIssues" @click="openIssuesPageNext()"></div>
                                </div>
                            </template>
                            <template v-else>
                                <EmptyPage :title="$t('sensor.empty_alerts')" :description="$t('empty_pages.sensor_description')"></EmptyPage>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import Tabs from '../components/domain/ui/Tabs.vue'
import EmptyPage from '../components/layout/EmptyPage.vue'
import ViewCharts from '../components/domain/sensor/viewCharts.vue'
import i18n from '@/i18n'
import SensorDetail from '@/components/domain/sensor/sensorDetail.vue'
import SensorAlertCard from '@/components/domain/sensor/sensorAlertCard.vue'
import chartSensor from '@/components/domain/sensor/chartSensor.vue'
import VirtualList from 'vue-virtual-scroll-list'
import ConnectionBox from '@/components/domain/ui/Box'

export default {
    components: { chartSensor, SensorAlertCard, SensorDetail, VirtualList, ViewCharts, EmptyPage, Tabs, ConnectionBox },
    name: 'Sensors',
    data() {
        return {
            chartComponent: chartSensor,
            sensorCharts: true,
            allIssuesSelected: true,
            sensorSelected: false,
            key: 0,
            page: 1,
            perPage: 20,
            total: false,
            totalAlertsPaginated: [],
            openIssuesPaginated: [],
            pageOpenIssues: 1,
            perPageOpenIssues: 20,
            totalOpenIssues: false,
            openIssuesList: [],
            maxDate: true,
            minDate: true,
            showButtonFilter: false,
            currentFilters: {
                type_date: 'today',
                date: {
                    start_date: false,
                    end_date: false
                }
            },
            emptyFilters: {
                type_date: 'today',
                date: {
                    start_date: false,
                    end_date: false
                }
            },
            printCharts: false,
            cssImages: css_image_url,
            isSensorsLoaded: false,
            conection: true
        }
    },
    computed: {
        checkConnetion() {
            return this.$store.getters['getConnState']
        },
        optionsDate() {
            return [
                { value: 'today', name: i18n.t('assets.date.today_last_hours') },
                { value: 'yesterday', name: i18n.t('assets.date.yesterday') },
                { value: 'week', name: i18n.t('assets.date.last_week') }
            ]
        },
        // sensors() {
        //     let sensors = Object.values(this.$store.getters['sensor/getSensorValues']())
        //     return sensors
        // },
        sensors() {
            let sensors = Object.values(this.$store.getters['sensor/getList']())
            return sensors.filter((sensor) => {
                return sensor.status > 0
            })
        },
        issueStates() {
            return this.$store.getters['getIssueStates']
        },
        sensorAlerts() {
            return this.$store.getters['sensor/getSensorValues']()
        },
        sensorsWithAlerts() {
            if (this.sensorAlerts && Object.values(this.sensorAlerts).length > 0) {
                let sensors = Object.values(this.sensorAlerts)

                //filter if sensor has values
                sensors = sensors.filter((sensor) => {
                    return Object.values(sensor.values).length > 0
                })

                let aSensor = []
                sensors.forEach((sensor) => {
                    for (let index in sensor.values) {
                        if (sensor.values[index].alert) {
                            let sensorResult = {
                                id: sensor.id,
                                name: sensor.name,
                                data: { ref: sensor.values[index] },
                                timestamp: parseInt(index),
                                issue_id: sensor.values[index].issue ? sensor.values[index].issue.issue_id : false,
                                checklist_id: sensor.values[index].issue ? sensor.values[index].issue.id : false,
                                status: sensor.values[index].issue ? sensor.values[index].issue.status : false
                            }
                            aSensor.push(sensorResult)
                        }
                    }
                })
                // sort by timestamp
                aSensor.sort((a, b) => {
                    return b.timestamp - a.timestamp
                })
                this.total = aSensor.length
                this.totalAlertsPaginated = aSensor.slice((this.page - 1) * this.perPage, this.page * this.perPage)
                return aSensor
            }
        }
    },
    methods: {
        showCharts(param) {
            if (param) {
                this.sensorCharts = true
            } else {
                this.openIssues()
                this.sensorCharts = false
            }
        },
        calcDate() {
            this.currentFilters.date && this.currentFilters.date.start_date ? (this.maxDate = moment(this.currentFilters.date.start_date).format('YYYY-MM-DD')) : true
            this.currentFilters.date && this.currentFilters.date.end_date ? (this.minDate = moment(this.currentFilters.date.end_date).format('YYYY-MM-DD')) : true
            // Force update object
            this.currentFilters = { ...this.currentFilters }
        },
        load() {
            let self = this
            this.$overlay.loading()
            let date = this.currentFilters
            let params = {
                start_date: date.type_date != 'custom' ? date.type_date : date.date.start_date,
                end_date: date.type_date == 'custom' ? date.date.end_date : false
            }
            this.conection = fnCheckConnection()
            this.$store.dispatch('sensor/loadList').then(() => {
                this.$store.dispatch('sensor/loadSensorValues', params).then(() => {
                    if (self.$route.params.id) {
                        self.sensorSelected = self.$store.getters['sensor/getSensorValues'](self.$route.params.id).id
                    }
                    self.$overlay.hide()
                    if(fnCheckConnection()){
                        self.isSensorsLoaded = true
                    }
                })
            })
        },
        openIssues(prop) {
            let self = this
            if (prop == 'tabTwo') {
                this.$overlay.loading()
                let date = this.currentFilters
                let params = {
                    start_date: date.type_date != 'custom' ? date.type_date : date.date.start_date,
                    end_date: date.type_date == 'custom' ? date.date.end_date : false
                }
                // call endpoint
                if (Object.values(this.openIssuesList).length == 0) {
                    this.$store.dispatch('sensor/loadSensorOpenIssues', params).then((response) => {
                        self.openIssuesList = response
                        self.allIssuesSelected = false
                        self.numberOpenIssues = Object.values(response).length
                        self.$overlay.hide()
                    })
                } else {
                    self.allIssuesSelected = false
                    self.numberOpenIssues = Object.values(self.openIssuesList).length
                    self.$overlay.hide()
                }
            } else {
                self.allIssuesSelected = true
                self.$forceUpdate()
            }
        },
        back(filters) {
            this.sensorSelected = false
            this.$router.push({ name: 'Sensor' })
            if (JSON.stringify(filters) !== JSON.stringify(this.currentFilters)) {
                this.filterByDate(this.currentFilters)
            }
            this.printCharts = true
        },
        page_next() {
            this.page++
            this.totalAlertsPaginated = this.sensorsWithAlerts.slice(this.perPage * (this.page - 1), this.perPage * this.page)
        },
        page_prev() {
            this.page--
            this.totalAlertsPaginated = this.sensorsWithAlerts.slice(this.perPage * (this.page - 1), this.perPage * this.page)
        },
        openIssuesPageNext() {
            this.pageOpenIssues++
            this.openIssuesPaginated = Object.values(this.openIssuesList).slice((this.pageOpenIssues - 1) * this.perPageOpenIssues, this.pageOpenIssues * this.perPageOpenIssues)
        },
        openIssuesPagePrev() {
            this.pageOpenIssues--
            this.openIssuesPaginated = Object.values(this.openIssuesList).slice((this.pageOpenIssues - 1) * this.perPageOpenIssues, this.pageOpenIssues * this.perPageOpenIssues)
        },
        selectSensor(sensor) {
            this.sensorSelected = sensor.id
            this.$router.push({
                name: 'SensorDetail',
                params: {
                    id: sensor.id
                }
            })
        },
        filterDetail(detail) {
            let date = detail.filters
            let params = {
                id: detail.id,
                start_date: date.type_date != 'custom' ? date.type_date : date.date.start_date,
                end_date: date.type_date == 'custom' ? date.date.end_date : false
            }
            this.$store.dispatch('sensor/loadSensorValuesDetail', params).then(() => {
                // self.sensorSelected = self.$store.getters['sensor/getSensorValues'](self.$route.params.id)
            })
        },
        filterByDate(date) {
            let self = this

            let params = {
                start_date: date.type_date != 'custom' ? date.type_date : date.date.start_date,
                end_date: date.type_date == 'custom' ? date.date.end_date : false
            }
            this.emptyFilters = { ...this.currentFilters }
            this.$store.dispatch('sensor/loadSensorValues', params).then(() => {
                if (!self.sensorCharts) {
                    self.$store.dispatch('sensor/loadSensorOpenIssues', params).then((response) => {
                        self.openIssuesList = response
                        self.numberOpenIssues = Object.values(response).length
                        self.sensorsWithAlerts
                    })
                }
            })
        }
    },
    created() {
        this.load()
    },
    mounted() {
        if (!this.$route.params.id) {
            this.printCharts = true
        }
        this.conection = fnCheckConnection()
    },
    watch: {
        openIssuesList(newValue, oldValue) {
            if (newValue) {
                this.openIssuesPaginated = Object.values(newValue).slice((this.pageOpenIssues - 1) * this.perPageOpenIssues, this.pageOpenIssues * this.perPageOpenIssues)
                this.totalOpenIssues = Object.values(newValue).length
            }
        },
        currentFilters: {
            deep: true,
            handler: function (newValue, oldValue) {
                // compare with empty filters if is the same
                if (JSON.stringify(newValue) !== JSON.stringify(this.emptyFilters)) {
                    this.showButtonFilter = true
                } else {
                    this.showButtonFilter = false
                }
            }
        },
        emptyFilters: {
            deep: true,
            handler: function (newValue, oldValue) {
                // compare with empty filters if is the same
                if (JSON.stringify(newValue) !== JSON.stringify(this.currentFilters)) {
                    this.showButtonFilter = true
                } else {
                    this.showButtonFilter = false
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.sensors:not(.detail) {
    width: 100%;
    height: 100%;
    padding: 12px;
    .topbar-container {
        @include display-flex();
        @include justify-content(space-between);
        width: 100%;
        height: 45px;
        .left-side {
            @include display-flex();
            .chart-icon,
            .alert-icon {
                width: 45px;
                height: 45px;
                @include border-radius(4px);
                background-color: #fff;
                @include display-flex();
                justify-content: center;
                align-items: center;
                margin-right: 7px;
                cursor: pointer;

                &.selected {
                    background-color: $main;
                }

                img {
                    width: 60%;
                    height: 60%;
                }
            }
            .chart-icon {
                @include background($image: img('chart_inactive.svg'), $size: 60%);

                &.selected {
                    @include background($image: img('chart_active.svg'), $size: 60%);
                }
            }
            .alert-icon {
                @include background($image: img('alert_grey.svg'), $size: 70%);

                &.selected {
                    @include background($image: img('alert_fff.svg'), $size: 70%);
                }
            }
        }

        .right-side {
            .refresh-icon {
                @include display-flex();
                @include justify-content(center);
                @include align-items(center);
                gap: 8px;
                height: 45px;
                @include border-radius(4px);
                background-color: #fff;
                cursor: pointer;
                padding: 0px 8px;

                img {
                    width: 60%;
                    height: 60%;
                }

                span {
                    @include font-size(sm);
                    color: $color-black;
                    font-family: Avenir-Bold;
                }

                &.portrait {
                    width: 45px;
                    img {
                        width: 100%;
                        height: 80%;
                    }
                }
            }
        }
    }
    .sensor-chart-container {
        width: 100%;
        padding-top: 17px;
        height: calc(100% - 35px);
        overflow: hidden;

        .sensor-content {
            @include display-flex();
            @include flex-wrap(wrap);
            @include justify-content(flex-start);
            @include align-items(flex-start);
            align-content: flex-start;
            gap: 12px;
            width: 100%;
            height: calc(100% - 60px);
            overflow: scroll;
            .virtual-list {
                height: 400px;
                overflow-y: auto;
                width: 100%;
            }
        }

        .issues-sensor {
            width: 100%;
            height: 100%;

            .issues-selector {
                @include display-flex();
                @include justify-content(space-between);
                @include align-items(center);
                @include border-radius(4px);
                width: 100%;
                max-width: 500px;
                height: 40px;
                padding: 3px;
                background-color: #fff;
                margin: 0 auto;
                margin-bottom: 24px;
            }
            .list-issues-card-container {
                width: 100%;
                height: calc(100% - 50px);
                overflow: hidden;

                .list-issues-content {
                    @include display-flex();
                    width: 100%;
                    height: calc(100% - 80px);
                    overflow: scroll;
                    flex-wrap: wrap;
                    gap: 12px;
                    align-content: flex-start;
                    margin-bottom: 12px;
                }
            }
        }
        .pagination {
            width: 100%;
            display: block;
            height: 50px;

            .item {
                width: calc(50% - 10px);
                height: 45px;
                float: left;
                @include border-radius(4px);
                @extend .interaction;

                &.next {
                    @include background($image: img('right_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
                    float: right;
                }
                &.prev {
                    @include background($image: img('left_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
                }
            }
        }
    }
    &.portrait {
        .pagination {
            width: 100%;
            padding: 0px;
            display: block;
            height: 45px;
            // margin-top: 75px;
            position: relative;
            // top: -75px;
            margin-bottom: 10px;

            .item {
                width: calc(50% - 5px);
                height: 45px;
                margin: 0 0px;
                float: left;
                @include border-radius(4px);
                @extend .interaction;

                &.next {
                    @include background($image: img('right_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
                    float: right;
                    @extend .interaction;
                }
                &.prev {
                    @include background($image: img('left_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
                }
            }
        }
    }
}
.sensors.detail {
    width: 100%;
    height: 100%;
    padding: unset;
    overflow: hidden;
}
</style>
