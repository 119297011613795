<template>
    <div id="sensoralertcard" :class="[$mq]">
        <div class="top-card" v-if="sensor.data.ref">
            <div class="left-top-card">{{ sensor.data.ref.value }}ºC</div>
            <div class="right-top-card">
                <div class="sensor-name">{{ getSensorName ? getSensorName.name : sensor.name ? sensor.name : sensorName }}</div>
                <div class="date-sensor">{{ formatDate(sensor.data.ref.date ? sensor.data.ref.date : sensor.timestamp * 1000) }}</div>
            </div>
        </div>
        <div class="bottom-card" v-if="sensor.checklist_id || sensor.template_id" @click="goToIssue()">
            <div class="issue-id" v-if="sensor.issue_id">
                {{ '#' + sensor.issue_id }}
            </div>
            <div class="issue-card">
                <div class="ball" v-if="Object.values(issueStates).length > 0 && issueStates[sensor.status] && issueStates[sensor.status].color" :style="{ backgroundColor: issueStates[sensor.status].color }"></div>
                <div class="issue-name" v-if="Object.values(issueStates).length > 0 && issueStates[sensor.status] && issueStates[sensor.status].name">
                    {{ issueStates[sensor.status].name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SensorAlertCard',
    props: {
        sensor: {
            type: Object,
            default: false
        },
        sensorName: {
            type: [String, Boolean],
            default: false
        }
    },
    data() {
        return {
            val: 'value'
        }
    },
    computed: {
        date() {
            return moment(this.sensor.timestamp * 1000).format('DD/MM/YYYY HH:mm')
        },
        issueStates() {
            return this.$store.getters['getIssueStates']
        },
        getSensorName() {
            if (this.sensorName) {
                return this.$store.getters['sensor/getList'](this.sensorName)
            }
        }
    },
    methods: {
        goToIssue() {
            let id = typeof this.sensor.data.ref != 'undefined' && typeof this.sensor.data.ref.issue != 'undefined' && typeof this.sensor.data.ref.issue.id != 'undefined' ? this.sensor.data.ref.issue.id : this.sensor.id
            this.$router.push({ path: '/issues/' + id, params: { id: id } })
        },
        formatDate(date, format) {
            return this.$root.formatMoment(date, format)
        }
    }
}
</script>

<style lang="scss">
#sensoralertcard {
    width: calc(100%);
    height: fit-content;
    @include border-radius(6px);
    border-left: 4px solid $error-dark;
    @include display-flex();
    @include flex-direction(column);

    // media and max-width
    @media (max-width: 600px) {
        width: calc(100%);
    }

    @media (min-width: 601px) {
        width: calc(50% - 6px);
    }

    @media (min-width: 1200px) {
        width: calc(33% - 6px);
    }

    @media (min-width: 1600px) {
        width: calc(25% - 9px);
    }

    .top-card {
        @include display-flex();
        @include align-items(center);
        width: 100%;
        height: 60px;
        background-color: #fff;
        margin-bottom: 3px;

        .left-top-card {
            @include font-size(ml);
            font-family: $text-bold;
            color: $error-dark;
            padding-left: 5px;
            padding-right: 14px;
        }
        .right-top-card {
            @include display-flex();
            @include flex-direction(column);
            @include justify-content(flex-start);
            width: calc(100% - 110px);

            .sensor-name {
                @include text-ellipsis();
                @include font-size(m);
                font-family: $text-bold;
                color: $inactive-s90;
                width: calc(100% - 8px);
            }
            .date-sensor {
                @include font-size(s);
                @include text-ellipsis();
                @include background($image: img('input_date_cccccc.svg'), $position: left center);
                padding-left: 30px;
                color: $inactive-s90;
            }
        }
    }
    .bottom-card {
        border-radius: 0px 5px 5px 5px;
        width: 100%;
        background-color: $default-sec-color;
        padding: 10px;
        @include display-flex();
        @include align-items(center);
        @include justify-content(flex-start);
        @include background($image: img('right_inactive_s50.svg'), $position: right 40px center, $size: 16px);
        cursor: pointer;

        &:hover {
            @include bgHover($default-sec-color);
        }

        .issue-id {
            @include font-size(m);
            @include border-radius(4px);
            font-family: $text-bold;
            color: #07090f;
            padding: 5px 10px;
            background-color: #fff;
        }
        .issue-card {
            @include display-flex();
            @include align-items(center);
            @include justify-content(flex-start);
            margin-left: 10px;

            .ball {
                @include border-radius(50%);
                width: 15px;
                height: 15px;
                background-color: $error-dark;
                margin-right: 5px;
            }
            .issue-name {
                @include font-size(sm);
                font-family: $text-light;
                color: $neutro-s60;
            }
        }
    }

    &.portrait {
        .top-card {
            .left-top-card {
                @include font-size(s);
            }
            .sensor-name {
                @include font-size(s);
            }
        }
        .bottom-card {
            .issue-id {
                @include font-size(s);
            }
            .issue-name {
                @include font-size(s);
            }
        }
    }
}
</style>
