<template>
    <div class="single-sensor-chart" :class="[$mq]">
        <div class="top-chart" @click="selectSensor()" :class="{ alert: hasAlerts }">
            <div class="left-container" :class="{ 'no-alerts': !hasAlerts }">
                <div class="alerts" v-if="hasAlerts">
                    <div class="number">{{ hasAlerts }}</div>
                    <div class="icon-img"></div>
                </div>
                <div class="check" v-else></div>
            </div>
            <div class="right-container">
                <div class="name-sensor">{{ sensor.name }}</div>
                <div class="id-sensor">
                    <div class="bold" v-html="$t('sensor.ref_id')"></div>
                    {{ sensor.ref }}
                </div>
            </div>
        </div>
        <div class="chart-container" v-if="sensorChartValues && show">
            <apexchart :ref="'chart' + sensor.id" width="99%" type="line" height="auto" :options="chartOptions" :series="series"></apexchart>
        </div>
        <div class="chart-container" v-else>
            <div class="greybox"></div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
export default {
    name: 'ChartSensor',
    props: {
        sensor: {
            type: Object,
            default: false
        }
    },
    data() {
        return {
            hasAlerts: false,
            show: false,
            sendValueCharts: false,
            chartOptions: {
                chart: {
                    animations: {
                        enabled: false
                    },
                    id: this.sensor.id,
                    toolbar: {
                        show: false
                    },
                    background: '#fff'
                },
                grid: {
                    show: false,
                    padding: {
                        left: 0,
                        right: 0
                    }
                },
                tooltip: {
                    x: {
                        show: false
                    },
                    y: {
                        formatter: function (value) {
                            // without decimals
                            return value.toFixed(1)
                        }
                    }
                },

                xaxis: {
                    categories: [],
                    hideOverlappingLabels: true,
                    // range: 5,
                    style: {
                        fontSize: '20px'
                    },
                    formatter: function (value) {
                        return moment(value).format('HH:mm')
                    }
                },
                //nodata
                noData: {
                    text: i18n.t('sensor.no_data'),
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: '#000',
                        fontSize: '14px',
                        fontFamily: 'Avenir-Regular'
                    }
                },
                legend: {
                    show: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 6
                },
                datalabels: {
                    enabled: true,
                    enabledOnSeries: [0],
                    formatter: function (value) {
                        // without decimals
                        return value.toFixed(1)
                    },
                    style: {
                        colors: ['#000']
                    },
                    background: {
                        enabled: true,
                        foreColor: '#fff',
                        padding: 2,
                        borderRadius: 2,
                        borderWidth: 1,
                        borderColor: '#fff',
                        opacity: 0.9
                    }
                }
            }
        }
    },
    computed: {
        sensorChartValues() {
            if (this.sensor && this.sensor.id) {
                let sensor = this.$store.getters['sensor/getSensorValues'](this.sensor.id)
                if (sensor && sensor.alerts && sensor.alerts > 0) {
                    this.hasAlerts = sensor.alerts
                }
                if (sensor && sensor.values && Object.values(sensor.values).length > 0) {
                    return sensor.values
                } else {
                    return 'nodata'
                }
            }
            return false
        },

        series() {
            if (!this.show) {
                return []
            }
            if (this.sendValueCharts) {
                let data = []
                let serie = []
                let sensor = this.sensorChartValues
                if (sensor && typeof sensor != 'string') {
                    if (Object.values(sensor).length > 0) {
                        Object.values(sensor).forEach((value) => {
                            data.push(value.value)
                        })
                        serie.push({
                            name: this.sensor.name,
                            data: data
                        })
                    }
                    //if has alerts add new line to series
                    if (typeof this.sensor.config != 'undefined' && this.sensor.config != null && this.sensor.config) {
                        // add the same values the same times
                        let alertValues = []
                        let alertValues2 = []

                        // if is string means has 2 values, if not is integer with one value
                        if (typeof this.sensor.config.value == 'string') {
                            for (let i = 0; i < data.length; i++) {
                                alertValues.push(this.sensor.config.value.split(',')[0])
                                alertValues2.push(this.sensor.config.value.split(',')[1])
                            }
                        } else {
                            for (let i = 0; i < data.length; i++) {
                                alertValues.push(this.sensor.config.value)
                            }
                        }
                        serie.push({
                            name: 'Alerta',
                            data: alertValues,
                            color: '#ffc700',
                            width: 1
                        })
                        if (alertValues2.length > 0) {
                            serie.push({
                                name: 'Alerta 2',
                                data: alertValues2,
                                color: '#ffc700',
                                width: 1
                            })
                        }
                    }
                }
                return serie
            }
        }
    },
    methods: {
        chartFinish() {
            this.$overlay.hide()
            this.sendValueCharts = true
        },
        selectSensor() {
            this.$emit('selectSensor', this.sensor)
        },
        xAxisValues() {
            let self = this
            let dates = []
            let min = 9000
            let max = -1000
            //push dates with different hours
            if (this.sensorChartValues && typeof this.sensorChartValues != 'string') {
                for (let key in this.sensorChartValues) {
                    // console.log(key)
                    let keyInt = parseInt(key * 1000)
                    dates.push(moment(keyInt).format('DD/MM HH') + 'h')
                    // get min and max from values
                    // if (this.sensorChartValues[key].value < min) {
                    //     min = this.sensorChartValues[key].value
                    // }
                    // if (this.sensorChartValues[key].value > max) {
                    //     max = this.sensorChartValues[key].value
                    // }
                }

                // rest 5 to min and add 5 to max
                // min = Math.round(min) - 3
                // max = Math.round(max) + 3

                this.updateAxis(dates, min, max)
            } else {
                self.show = true
            }
        },
        updateAxis(data, min, max) {
            let copy = { ...this.chartOptions }

            copy.stroke = {
                curve: 'smooth',
                width: 6
            }

            copy.events = {
                mounted: this.chartFinish()
            }

            copy.xaxis = {
                categories: data,
                convertedCatToNumeric: false,
                hideOverlappingLabels: true,
                tickAmount: 3,
                tickPlacement: 'on',
                offsetX: 30,
                labels: {
                    show: true,
                    rotate: 0,
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Avenir-Regular'
                    }
                }
                // range: 20,
            }
            copy.yaxis = {
                // min: min,
                // max: max,
                convertedCatToNumeric: false,
                labels: {
                    show: true,
                    style: {
                        fontSize: '11px',
                        fontFamily: 'Avenir-Bold-Condensed'
                    },
                    formatter: function (value) {
                        // without decimals
                        return value.toFixed(0)
                    }
                }
            }
            this.chartOptions = copy
            let self = this
            setTimeout(() => {
                self.show = true
            }, 1000)
        }
    },
    mounted() {
        let self = this
        setTimeout(() => {
            self.show = true
        }, 200)
        if (this.sensorChartValues) {
            this.xAxisValues()
        }
    },
    watch: {
        sensorChartValues(newValue, oldValue) {
            this.show = false
            if (newValue) {
                this.xAxisValues()
            }
        }
    }
}
</script>

<style lang="scss">
.single-sensor-chart {
    width: calc(100%);
    height: auto;
    box-shadow: 0px 0px 5px #e3e7ed;

    // media and max-width
    @media (max-width: 600px) {
        width: calc(100%);
    }

    @media (min-width: 601px) {
        width: calc(50% - 6px);
    }

    @media (min-width: 1200px) {
        width: calc(33% - 6px);
    }

    @media (min-width: 1600px) {
        width: calc(25% - 9px);
    }

    .top-chart {
        @include display-flex();
        @include align-items(center);
        background-color: $inactive-t80;
        border-radius: 2px 2px 0px 0px;
        padding: 7px;
        height: 50px;

        .left-container {
            @include display-flex();
            @include align-items(center);
            @include justify-content(center);
            background-color: #fff;
            height: 27px;
            padding: 6px;

            .alerts {
                @include display-flex();
                @include align-items(center);
                .number {
                    font-family: $text-bold;
                    @include font-size(sm);
                    padding-right: 4px;
                    position: relative;
                    top: 2px;
                }
                .icon-img {
                    // @include background($image: img('alert_sensor.svg'), $position: center, center);
                    background-image: url('../../../../public/img/alert_sensor.svg');
                    width: 17px;
                    height: 17px;
                    background-size: 100%;
                }
            }
            .check {
                background-image: url('../../../../public/img/checked_ok.svg');
                width: 30px;
                height: 30px;
                background-size: 100%;
            }
            &.no-alerts {
                background-color: unset;
            }
        }
        .right-container {
            @include display-flex();
            @include flex-direction(column);
            @include justify-content(center);
            padding-left: 12px;
            .name-sensor {
                @include text-ellipsis();
                font-family: $text-bold;
                width: 100%;
                @include font-size(m);
            }
            .id-sensor {
                @include display-flex();
                font-family: $text-xlight;
                @include font-size(s);
                color: $neutro-s80;
            }
        }
        &.alert {
            cursor: pointer;
            $alert: #ffc700;
            background-color: $alert;

            &:hover {
                @include bgHover($alert);
            }
        }
        &:hover {
            cursor: pointer;
            @include bgHover($inactive-t80);
        }
    }
    .chart-container {
        width: 100%;
        height: 100%;
        background-color: #fff;
        box-shadow: 0px 0px 5px #e3e7ed;
        @include border-radius(0px 0px 2px 2px);
        height: calc(100% - 60px);

        &.nodata {
            @include display-flex();
            @include align-items(center);
            @include justify-content(center);
        }
        .greybox {
            height: 250px;
            @include background($image: img('loader_dark.svg'), $position: center center, $size: 50px, $color: #fff);
        }
    }
    &.portrait {
        .right-container {
            .name-sensor {
                @include font-size(s);
            }
            .id-sensor {
                @include font-size(xs);
            }
        }
    }
}
</style>
